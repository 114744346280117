import React from "react";
import styled from "styled-components";
import { navigate } from "gatsby-link";

import { PrimaryButton } from "../Buttons";
import { H3 } from "../Text";

import LanguageProps from "../../interfaces/LanguageProps";

const Container = styled.div`
  display: grid;
  gap: 2rem 0;
  padding: 2rem;
  background: ${({ theme }) => theme.colors.lightGrey};
  height: fit-content;
`;

interface RegisterSectionProps extends LanguageProps {
  className?: string;
}

const RegisterSection: React.FC<RegisterSectionProps> = ({
  className,
  language,
}) => {
  const isEnglish = language === "en";
  const navigateToRegisterPage = () => {
    navigate(isEnglish ? "/en/registreren/" : "/registreren/");
  };

  return (
    <Container className={className}>
      <H3>{isEnglish ? "Register" : "Registreren"}</H3>
      <p>
        {isEnglish
          ? "If you don't yet have a Schuiflade.nl account, choose this option to request an account first. When your details have been verified, you can log in and purchase our products."
          : "Indien u nog geen Schuiflade.nl account heeft, kies dan deze optie om eerst een account aan te vragen. Wanneer uw gegevens zijn gecontroleerd kunt u inloggen en onze producten aanschaffen."}
      </p>
      <PrimaryButton onClick={navigateToRegisterPage}>
        {isEnglish ? "Register" : "Registreren"}
      </PrimaryButton>
    </Container>
  );
};

export default RegisterSection;
