import React from "react";
import styled from "styled-components";
import toast from "react-hot-toast";
import { Controller, useForm } from "react-hook-form";
import { navigate } from "gatsby-link";

import { Input } from "../Form";
import { PrimaryButton } from "../Buttons";
import { useAuth } from "../../hooks/useAuth";

import LanguageProps from "../../interfaces/LanguageProps";

const Form = styled.form`
  display: grid;
  gap: 2rem 0;
  padding: 2rem;
  background: ${({ theme }) => theme.colors.lightGrey};
`;

interface LoginFormProps extends LanguageProps {
  className?: string;
}

interface LoginFormData {
  emailAddress: string;
  password: string;
}

const LoginForm: React.FC<LoginFormProps> = ({ className, language }) => {
  const isEnglish = language === "en";
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { signIn } = useAuth();
  const { handleSubmit, control } = useForm<LoginFormData>({
    mode: "onSubmit",
  });

  const onValidSubmit = async (loginData: LoginFormData) => {
    setIsLoading(true);
    const { emailAddress, password } = loginData;

    const loadingMessage = isEnglish ? "Logging in.." : "Inloggen..";
    const successMessage = isEnglish ? "Logged in" : "Ingelogd";
    const errorMessage = isEnglish ? "No access" : "Geen toegang";

    toast
      .promise(signIn(emailAddress, password), {
        loading: loadingMessage,
        success: successMessage,
        error: errorMessage,
      })
      .then(() => (isEnglish ? navigate("/en/") : navigate("/")))
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const onInvalidSubmit = async () => {
    const errorMessage = isEnglish
      ? "Please fill in all details"
      : "Vul alstublieft alle gegevens in";

    toast.error(errorMessage);
  };

  return (
    <Form
      className={className}
      onSubmit={handleSubmit(onValidSubmit, onInvalidSubmit)}
    >
      <Controller
        control={control}
        name="emailAddress"
        rules={{ required: true }}
        render={({ field }) => (
          <Input
            id="emailAddress"
            autoComplete="username"
            name="emailAddress"
            type="email"
            label={isEnglish ? "E-mail Address" : "E-mailadres"}
            onChange={field.onChange}
          />
        )}
      />
      <Controller
        control={control}
        name="password"
        rules={{ required: true }}
        render={({ field }) => (
          <Input
            id="password"
            autoComplete="current-password"
            name="password"
            type="password"
            label={isEnglish ? "Password" : "Wachtwoord"}
            onChange={field.onChange}
          />
        )}
      />
      <PrimaryButton type="submit" disabled={isLoading}>
        {isEnglish ? "Login" : "Inloggen"}
      </PrimaryButton>
    </Form>
  );
};

export default LoginForm;
