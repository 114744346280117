import React, { useEffect } from "react";
import { navigate } from "gatsby-link";
import styled from "styled-components";

import { PaddingContainer } from "../Containers";
import { LoginForm, RegisterSection } from ".";
import { H1 } from "../Text";

import { useAuth } from "../../hooks/useAuth";
import LanguageProps from "../../interfaces/LanguageProps";

const FormsContainer = styled.div`
  display: grid;
  gap: 2rem 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: repeat(2, 1fr);
    gap: 0 2rem;
  }
`;

const Login = ({ language }: LanguageProps) => {
  const { isAuthenticated } = useAuth();
  const isEnglish = language === "en";

  useEffect(() => {
    if (isAuthenticated) navigate("/app/account/");
  }, []);

  return (
    <PaddingContainer>
      <H1>{isEnglish ? "Login" : "Inloggen"}</H1>
      <FormsContainer>
        <LoginForm language={language} />
        <RegisterSection language={language} />
      </FormsContainer>
    </PaddingContainer>
  );
};

export default Login;
