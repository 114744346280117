import React from "react";
import { PageProps } from "gatsby";

import SEO from "../components/SEO";
import { Login } from "../components/Login";

const EnglishLoginPage: React.FC<PageProps> = ({ location }) => (
  <>
    <SEO title="Login" lang="en" pathName={location.pathname} />
    <Login language="en" />
  </>
);

export default EnglishLoginPage;
